<template>
  <div class="page">
    <div id="pdfContent" class="cotent"></div>

    <!-- <div class="footer">
      <van-button type="primary" @click="resign" block>重新签署</van-button>
    </div> -->
  </div>
</template>

<script>
  import Pdfh5 from 'pdfh5'
  import 'pdfh5/css/pdfh5.css'
  export default {
    data: () => ({
      pdfh5: null
    }),
    mounted () {
      this.init()
    },
    methods: {
      init () {
        const url = window.sessionStorage.getItem('laborAgreementUrl')
        console.log(url)
        this.pdfh5 = new Pdfh5('#pdfContent', {
          pdfurl: url,
          lazy: false, // 是否懒加载,默认false
          renderType: 'canvas', // canvas、svg，默认canvas
          maxZoom: 3, // 手势缩放最大倍数,默认3
          scrollEnable: true, // 是否允许pdf滚动,默认true
          zoomEnable: true,// 是否允许pdf手势缩放,默认true
          limit: 0, // 限制pdf加载最大页数,默认0不限制
          goto: 1,
        });
        console.log('pdfh5', this.pdfh5)
        // 监听pdf准备开始渲染，此时可以拿到pdf总页数
        this.pdfh5.on('ready', function(totalNum) {
          console.log('总页数:' + totalNum);
        });
        // 监听pdf加载完成事件，加载失败、渲染成功都会触发
        this.pdfh5.on('complete', (status, msg, time) => {
          console.log('状态:' + status + '，信息:' + msg + '，耗时:' + time + '毫秒');
        })
      },

      resign() {
        this.$router.push('/meeting/agree/sign')
      }
    }
  }
</script>

<style lang="less" scoped>
.page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.cotent {
  width: 100vw;
  flex: 1;
}

.footer {
  border-top: 1px solid #efefef;
  padding: 5.33vw 4.27vw 13.33vw;
  background: #fff;
}

</style>